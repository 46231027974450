<template>
    <div>
        <vue-apex-charts type="bar" :height="height" :series="series" :options="options" />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    props: ['datos', 'height'],
    components: {
        VueApexCharts
    },
    data: () => ({
        options: 
        {
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            chart: { 
                width: '100%',
                height: '100%',
                offsetX: 0,
                offsetY: 0,
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: ['Desistida', 'En Revisión', 'Aprobadas']
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false
            }
        }
    }),
    computed: {
        series() {
            return this.datos.series || [];
        }
    }
}
</script>