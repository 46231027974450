<template>
    <div>
        <v-row>
            <v-col>
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_sias_create' }">
                    <v-icon class="mr-2">assignment</v-icon>
                    <span class="subtitle-2">Cargar SIA</span>
                </v-btn>
                <v-btn v-if="$parent.$parent.get_permission('export')"  class="ma-1" large solo depressed color="secondary" @click="$refs.exportar_dialog.show()">
                    <v-icon class="mr-2">file_copy</v-icon>
                    <span class="subtitle-2">Exportar Tabla</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <DashCard title="Resúmen" body-class="pa-0">
                    <v-skeleton-loader type="list-item, list-item, list-item, list-item, list-item" :loading="resumen_loading">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Estado</th>
                                        <th class="text-right">Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(estado, i) in totales.total_estados" v-bind:key="i">
                                        <td><b>{{ estado.nombre }}</b></td>
                                        <td class="text-right">{{ estado.total }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total Solicitudes</b></td>
                                        <td class="text-right">{{ (totales.total_inscritos || 0) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" md="8">
                <DashCard title="Gráfico">
                    <v-skeleton-loader type="image" :loading="resumen_loading">
                        <ChartResumenSia :datos="grafico" height="250" />
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <DashCard title="Solicitudes" body-class="pa-0">
                    <BuscadorTabla ref="buscador" class="px-4 pt-4" v-model="busqueda" :target="tabla_items" :types="busqueda_settings" :estados="estados" />
                    <v-divider />
                    <v-skeleton-loader type="table" :loading="tabla_loading">
                        <v-data-table dense :headers="headers" :items="tabla_items" :search="busqueda" :custom-filter="filtro" :mobile-breakpoint="0">
                            <template v-slot:item.fecha_solicitud="{ item }">
                                {{ item.fecha_solicitud | moment("L") }}
                            </template>
                            <template v-slot:item.id="{ item }">
                                <router-link :to="{ name: 'dashboard_sias_item', params: { id: item.id }}">{{ item.id }}</router-link>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <v-chip dark :color="item.estado | color_estado('sia')">
                                    {{ item.estado | estado("sia") }}
                                </v-chip>
                            </template>
                            <template v-slot:item.descripcion_tipo_obra="{ item }">
                                <span :title="item.descripcion_tipo_obra">{{ item.descripcion_tipo_obra | see_more(80) }}</span>
                            </template>
                            <template v-slot:item.persona_autor.first_name="{ item }">
                                <v-chip dark color="indigo" v-if="!!item.persona_autor">
                                    <v-avatar left>
                                        <v-icon>account_circle</v-icon>
                                    </v-avatar>
                                    {{ item.persona_autor.first_name }} {{ item.persona_autor.last_name }}
                                </v-chip>
                                <template v-else>
                                    No asignado
                                </template>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-menu offset-y v-if="$parent.$parent.get_permission('read') || $parent.$parent.get_permission('edit') || $parent.$parent.get_permission('delete')">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text fab small color="primary" depressed v-on="on">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                        <v-list-item v-if="$parent.$parent.get_permission('read')" :to="{ name: 'dashboard_sias_item', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>visibility</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Ver</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit')" :to="{ name: 'dashboard_sias_edit', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit') && $parent.$parent.get_permission('delete')" @click="ask_eliminar(item)">
                                            <v-list-item-icon>
                                                <v-icon>delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <DialogExportarTablaGeneral ref="exportar_dialog" type="sias" />
        <CommonDialog ref="dialog_delete" @onClose="delete_item" />
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import DashCard from '@/shared/components/DashCard'
import DialogExportarTablaGeneral from '@/dash/dialogs/DialogExportarTablaGeneral'
import ChartResumenSia from '@/dash/components/ChartResumenSia'
import BuscadorTabla from '@/shared/components/BuscadorTabla'
import CommonDialog from '@/shared/components/CommonDialog'
import raw_estados from '@/assets/estados'

export default {
    components: {
        DashCard,
        ChartResumenSia,
        BuscadorTabla,
        DialogExportarTablaGeneral,
        CommonDialog
    },
    data: () => ({
        resumen: {},
        busqueda: "",
        items: null,
        estados: [
            { 
                columna: "estado", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los estados' }, ...raw_estados['sia']] 
            }
        ],
        busqueda_settings: [
            { 
                value: null, 
                text: "Todo",
            },
            { 
                value: "id", 
                text: "ID Interno",
            },
            { 
                value: "cod_externo", 
                text: "ID Externo",
            },
            { 
                value: "fecha_solicitud", 
                text: "Fecha Solicitud",
            },
            { 
                value: "area_responsable", 
                text: "Área",
            },
            { 
                value: "empresa",
                text: "Empresa"
            },
            { 
                value: "gerencia_responsable", 
                text: "Gerencia"
            },
            { 
                value: "persona_autor", 
                text: "Responsable"
            }
        ],
        headers: [
            { text: "ID", value: "id" },
            { text: "Externo", value: "cod_externo" },
            { text: "Fecha Solicitud", value: "fecha_solicitud" },
            { text: "Empresa", value: "empresa.nombre" },
            { text: "Solicitante", value: "persona_empresa_solicitante" },
            { text: "Área", value: "area_responsable.nombre" },
            { text: "Gerencia", value: "gerencia_responsable.nombre" },
            { text: "Tipo de Obra", value: "descripcion_tipo_obra" },
            { text: "Responsable", value: "persona_autor.first_name" },
            { text: "Estado", value: "estado" },
            { text: "", value: "actions", sortable: false }
        ]
    }),
    computed: {
        resumen_loading() {
            return !Object.values(this.resumen).length;
        },
        tabla_loading() {
            return !this.items;
        },
        tabla_items() {
            if(!this.$refs.buscador) return this.items;
            return this.items.filter(x => this.$refs.buscador.filter_estado(x));
        },
        totales() {
            return this.resumen.totales || {};
        },
        grafico() {
            return this.resumen.grafico || {};
        }
    },
    methods: {
        filtro(value, search, item) {
            return this.$refs.buscador.filtro(value, search, item);
        },
        ask_eliminar(item) {
            this.$refs.dialog_delete.showDialog(messages.titles['question_delete_item'], messages.texts['delete_item'], "yes_no", item);
        },
        delete_item(response, item) {
            if(response == 'yes') {
                axiosHelper.del('sias/' + item.id + '/')
                    .then(response => {
                        this.$parent.$parent.reloadComponent();
                    });
            }
        }
    },
    created() {
        this.$emit('title', { text: "Solicitudes de Intervención de Área", icon: "inbox" })

        axiosHelper.get('sias')
            .then(response => {
                this.items = response.data.list;
                this.resumen = response.data.resumen;
            });
    }
}
</script>